// src/Components/SearchBar/SearchBar.js
import React from "react";
import { Form, FormControl, Button } from "react-bootstrap";
import "./SearchBar.css";

const SearchBar = () => {
  return (
    <Form className="d-flex search-bar">
      <FormControl
        type="search"
        placeholder="Search..."
        className="me-2 search-input"
        aria-label="Search"
      />
      <Button variant="outline-light" size="sm">
        Search
      </Button>
    </Form>
  );
};

export default SearchBar;





// const SearchBar = () => {
//     const [search, setSearch] = useState("");
//     const [searchData, setSearchData] = useState([])
//     const [selectedItem, setSelectedItem] = useState(-1)

//     const handleChange = e => {
//         setSearch(e.target.value);
//     }

//     const handleClose = () => {
//         setSearch("");
//         setSearchData([]);
//         setSelectedItem(-1);
//     }

//     const handleKeyDown = e => {
//         if (selectedItem < searchData.length) {
//             if (e.key === "ArrowUp" && selectedItem > 0) {
//                 setSelectedItem(prev => prev - 1);
//             }
//             else if (e.key === "ArrowDown" && selectedItem < searchData.length - 1) {
//                 setSelectedItem(prev => prev + 1);
//             }
//             else if (e.key === "Enter" && selectedItem >= 0) {
//                 window.open(searchData[selectedItem].show.url);
//             }
//         }
//         else {
//             setSelectedItem(-1);
//         }
//     }

//     useEffect(() => {
//         if (search !== " ") {

//             const newFilterdata = data.filter(product => {
//                 return product.name.includes(search);
//             })

//             setSearchData(newFilterdata);
//         }
//     },
//         [search])

//     return (
//         <section className='search_section'>
//             <div className=" dark:text-slate-800 search_input_div">
//                 <input
//                     type='text'
//                     className='search_input'
//                     placeholder='Search...'
//                     autoComplete='off'
//                     onChange={handleChange}
//                     value={search}
//                     onKeyDown={handleKeyDown}
//                 />
//                 <div className='search_icon'>
//                     {
//                         search === "" ? (<SearchIcon />) :
//                             (<CloseIcon onClick={handleClose} />)

//                     }
//                 </div>
//             </div>
//             <div className='search_result'>
//                 {
//                     searchData.map((data, index) => {
//                         return (
//                             <a href={data.link}
//                                 key={index}
//                                 target='_blank'
//                                 className={selectedItem === index ?
//                                     'search_suggestions_line active' :
//                                     'search_suggestions_line active'}>
//                                 {data.name}
//                             </a>
//                         );
//                     })
//                 }
//             </div>
//         </section>
//     );
// };

// export default SearchBar;
