import React, { createContext, useContext, useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Components/Cart/Cart.css";

const CartContext = createContext();

export default function CartProvider({ children }) {

  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {

    if (cart.length > 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  // const addToCart = (product) => {
  //   setCart((prevCart) => {
  //     console.log("Prev Cart:", prevCart); // לראות את עגלת הקניות לפני העדכון
  
  //     // בדוק אם המוצר כבר קיים בעגלה, בהשוואה לפי ה-ID בלבד
  //     const existingProduct = prevCart.find((item) => item._id === product._id); 
  //     console.log("Existing Product:", existingProduct); // לראות אם המוצר קיים
  
  //     if (existingProduct) {
  //       // אם המוצר כבר קיים, עדכן את הכמות שלו בלבד
  //       return prevCart.map((item) =>
  //         item._id === product._id
  //           ? { ...item, quantity: item.quantity + 1 }
  //           : item
  //       );
  //     } else {
  //       // אם המוצר לא קיים בעגלה, הוסף אותו כחדש
  //       return [...prevCart, { ...product, quantity: 1 }];
  //     }
  //   });
  // };

  const addToCart = (product, quantity = 1) => {
    setCart((prevCart) => {
        console.log("Prev Cart:", prevCart); // לראות את עגלת הקניות לפני העדכון

        // בדוק אם המוצר כבר קיים בעגלה, בהשוואה לפי ה-ID בלבד
        const existingProduct = prevCart.find((item) => item._id === product._id);
        console.log("Existing Product:", existingProduct); // לראות אם המוצר קיים

        if (existingProduct) {
            // אם המוצר כבר קיים, עדכן את הכמות שלו עם הכמות שנבחרה
            return prevCart.map((item) =>
                item._id === product._id
                    ? { ...item, quantity: item.quantity + quantity }
                    : item
            );
        } else {
            // אם המוצר לא קיים בעגלה, הוסף אותו כחדש עם הכמות שנבחרה
            return [...prevCart, { ...product, quantity }];
        }
    });
};


  

  const removeFromCart = (productId) => {
    setCart((prevCart) => {
      console.log("Cart before deletion:", prevCart);
      console.log("Product ID to remove:", productId);
  
      const updatedCart = prevCart.filter((item) => item._id !== productId);
      console.log("Cart after deletion attempt:", updatedCart);
  
      return updatedCart;
    });
    // toast("The product has been removed from the cart!");
  };
  
  
  const updateQuantity = (id, quantity) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === id ? { ...item, quantity: Math.max(1, quantity) } : item
      )
    );
  };

  const clearCart = () => {
    console.log("Clearing cart...");
    setCart([]);
    // toast("Your cart has been cleared!");
    localStorage.removeItem("cart");
    console.log("Cart after clearing:", cart); // בדוק אם העגלה מתעדכנת
  };

  return (
    <>
      <CartContext.Provider
        value={{ cart, addToCart, removeFromCart, updateQuantity, clearCart }}
      >
        {children}
        <ToastContainer />
      </CartContext.Provider>
    </>
  );
}

export function useCart() {
  return useContext(CartContext);
}