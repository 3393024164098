// components/admin/AdminDashboard.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
    Package, Users, ShoppingCart,
    TrendingDown, TrendingUp, AlertTriangle
} from 'lucide-react';
import imagAdmin from '../../assents/images/Admin.jpg'

export default function AdminDashboard() {
    const [stats, setStats] = useState({
        totalProducts: 0,
        totalOrders: 0,
        totalUsers: 0,
        lowStockProducts: [],
        recentOrders: []
    });

    useEffect(() => {
        fetchStats();
    }, []);

    const fetchStats = async () => {
        try {
            const response = await axios.get('http://localhost:3000/api/admin/stats', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('adminToken')}`
                }
            });
            setStats(response.data);
        } catch (error) {
            toast.error('Failed to fetch statistics');
        }
    };
    const styleTitle = {
        fontFamily: '"Anta", sans - serif',
        fontWeight: 400,
        fontStyle: 'normal'
    }

    return (
        <div className="space-y-6">
            <img src={imagAdmin} alt='Admin Panel' className='mx-auto'></img>
            <h1 className="text-lime-700 text-7xl font-bold text-center font-['Sigmar','sans-serif']">Management page</h1>
            
            {/* Stats Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="bg-white p-6 rounded-lg shadow">
                    <div className="flex items-center justify-between">
                        <div>
                            <p className="text-gray-500">Total Products</p>
                            <p className="text-2xl font-bold">{stats.totalProducts}</p>
                        </div>
                        <Package size={24} className="text-blue-500" />
                    </div>
                </div>

                <div className="bg-white p-6 rounded-lg shadow">
                    <div className="flex items-center justify-between">
                        <div>
                            <p className="text-gray-500">Total Orders</p>
                            <p className="text-2xl font-bold">{stats.totalOrders}</p>
                        </div>
                        <ShoppingCart size={24} className="text-green-500" />
                    </div>
                </div>

                <div className="bg-white p-6 rounded-lg shadow">
                    <div className="flex items-center justify-between">
                        <div>
                            <p className="text-gray-500">Total Users</p>
                            <p className="text-2xl font-bold">{stats.totalUsers}</p>
                        </div>
                        <Users size={24} className="text-purple-500" />
                    </div>
                </div>
            </div>

            {/* Low Stock Alert */}
            <div className="bg-white p-6 rounded-lg shadow">
                <h2 className="text-xl font-bold mb-4 flex items-center">
                    <AlertTriangle className="text-yellow-500 mr-2" />
                    Low Stock Products
                </h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full">
                        <thead>
                            <tr>
                                <th className="px-4 py-2">Product</th>
                                <th className="px-4 py-2">Current Stock</th>
                                <th className="px-4 py-2">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stats.lowStockProducts.map((product) => (
                                <tr key={product._id}>
                                    <td className="border px-4 py-2">{product.name}</td>
                                    <td className="border px-4 py-2">{product.stock}</td>
                                    <td className="border px-4 py-2">
                                        <span className="text-red-500">
                                            {product.stock < 10 ? "Out of stock ⚠️" : "Stock available 🆗"}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Recent Orders */}
            <div className="bg-white p-6 rounded-lg shadow">
                <h2 className="text-xl font-bold mb-4">Recent Orders</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full">
                        <thead>
                            <tr>
                                <th className="px-4 py-2">Order ID</th>
                                <th className="px-4 py-2">Customer</th>
                                <th className="px-4 py-2">Amount</th>
                                <th className="px-4 py-2">Status</th>
                                <th className="px-4 py-2">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stats.recentOrders.map((order) => (
                                <tr key={order._id}>
                                    <td className="border px-4 py-2">#{order._id.slice(-6)}</td>
                                    <td className="border px-4 py-2">{order.userId?.fullName}</td>
                                    <td className="border px-4 py-2">${order.totalAmount}</td>
                                    <td className="border px-4 py-2">
                                        <span className={`px-2 py-1 rounded ${order.status === 'completed' ? 'bg-green-100 text-green-800' :
                                            order.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                                                'bg-gray-100 text-gray-800'
                                            }`}>
                                            {order.status}
                                        </span>
                                    </td>
                                    <td className="border px-4 py-2">
                                        {new Date(order.date).toLocaleDateString()}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}