import React, { useEffect, useState } from 'react';
import CardProduct from '../CardProduct/CardProduct';
import axios from 'axios';

const ApplePhones = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("http://localhost:3000/api/products");
        const appleProducts = response.data.filter(product => product.categoryId === "phones" && product.companyId === "apple");
        setProducts(appleProducts);
      } catch (error) {
        console.error("Error fetching Apple products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="products">
    {products.map((product, index) => (
      <CardProduct
        key={index}
        product={product}
      />
    ))}
  </div>
  );
};

export default ApplePhones;
