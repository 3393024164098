import { Link } from 'react-router-dom';
import './AuthStyles.css';
import UserContext from "../../Context/UserContext.js";
import React, { useContext, useState } from 'react';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { CButton, CFormInput, CFormLabel } from "@coreui/react";
import axios from 'axios';

export default function Login() {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const Admins = {
    yakovAdmin: { userName: 'yakovAdmin', password: '0537470893' },
    yosefAdmin: { userName: 'yosefAdmin', password: '0521234456' }
  };
  
  const handleLogin = async (e) => {
    e.preventDefault();
  
    // בדיקת שדות חובה
    if (!userName.trim() || !password.trim()) {
      toast.error("Please fill in both fields.");
      return;
    }
  
    try {
      // שליחת בקשה לשרת
      const response = await axios.post('http://localhost:3000/api/users/login', {
        userName: userName.trim(),
        password: password.trim()
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
  
      console.log('Login response:', response);  // הדפסת התשובה לקונסול
  
      const { user, token } = response.data;
      
      // אם מדובר במנהל
      if ((userName === Admins.yakovAdmin.userName && password === Admins.yakovAdmin.password) ||
          (userName === Admins.yosefAdmin.userName && password === Admins.yosefAdmin.password)) {
        navigate("/admin");  // מעביר לדף ניהול המנהלים
      }
      // אם מדובר במשתמש רגיל
      else if (user && token) {
        setUserDetails({ ...user });
        localStorage.setItem('token', token);
        toast(`Logged in as ${user.userName}`);
        navigate("/PersonalArea");  // מעביר לאזור האישי
      } else {
        toast.error('User details not found');
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error(error.response?.data?.error || "An error occurred during login.");
    }
  };
  

  if (userDetails) {
    return (
      <div className='card' style={{ boxShadow: "-moz-initial", background: "#091057" }}>
        <h1 style={{ fontSize: "40px", color: "#F3C623" }}>
          Sorry, you are already logged in
        </h1>
      </div >
    )
  }



  return (
    <div className='mt-16'>
      <div className="flex items-center justify-center py-12 h-full">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-start mb-10">
            <h1 className="text-3xl font-bold ml-28">Sign In</h1>
            <p className="text-muted-foreground">
              Enter your email below to sign in to your account
            </p>
          </div>
          <form onSubmit={handleLogin} className="grid gap-4">
            <div className="grid gap-2">
              <CFormLabel htmlFor="userName">User Name</CFormLabel>
              <CFormInput
                id="userName"
                type="text"
                placeholder="Enter your userName"
                required
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="grid gap-2">
              <div className="flex items-center">
                <CFormLabel htmlFor="password">Password</CFormLabel>
                <Link
                  to="/request-reset"
                  className="ml-auto inline-block text-sm underline"
                >
                  Forgot your password?
                </Link>
              </div>
              <CFormInput
                id="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <CButton type="submit" className="w-full">
              Sign In
            </CButton>
            <div className="mt-4 text-center text-sm">
              Dont have an account?{" "}
              <Link to="/SignUp" className="underline">
                Sign up
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}