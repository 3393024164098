import React from "react";
import "./CardProduct.css";
import { useNavigate } from "react-router-dom";
import "../ProductDetail/ProductDetail.js";
import { useCart } from "../../Context/CartContext.js"
import UserContext from "../../Context/UserContext.js";
import { useContext } from "react";
import '../../products.css';


export default function CardProduct({ product }) {

  const { user } = useContext(UserContext);

  const { addToCart } = useCart();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/ProductDetail/${product._id}`);
  };

  const handleClickAdd = () => {
    addToCart(product)
  }
  return (
    <div className="container-product dark:text-slate-800">
      <div key={product.id}
        className="transition-all duration-500 product a"
      >
        <h3>{product.name}</h3>
        <img src={product.image}
          alt={product.name}
          onClick={handleClick} />
          
        <div className="view-text"
          onClick={handleClick}

        >View</div>
        <div className="details">
          <span className="price">₪ {product.price} </span>
        </div>
        <button className="btn-toCArt dark:bg-cyan-500 dark:hover:bg-sky-900 " onClick={handleClickAdd}>Add To Cart</button>
      </div>
    </div>
  );
}
