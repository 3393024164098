import React from "react";
import icon_Whtsapp from "../../assents/images/icons/icon-whatsapp.png";
const WhatsAppIcon = () => {
  return (
    <div className="fixed bottom-4 left-4">
      <a
        href={`https://wa.me/972537470893`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={icon_Whtsapp}
          alt="WhatsApp"
          className="w-16 h-16"
          style={{
            marginTop: "-30px",
            height: "30px",
            width: "30px",
            position: "fixed",
            top: "-10",
            marginLeft: "0px",
          }}
        />
      </a>
    </div>
  );
};

export default WhatsAppIcon;
