// App.js
import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home.js";
import Cart from "./Components/Cart/Cart.js";
import PersonalArea from "./Components/PersonalArea/PersonalArea.js";
import About from "./Components/About/About.js";
import Contact from "./Components/Contact/Contact.js";
import FAQ from "./Components/FAQ/FAQ.js";
import NavBar from "./Components/NavBar/NavBar.js";
import { ProductProvider } from "./Context/ProductContext.js";
import CartProvider from "./Context/CartContext.js";
import Tablets from "./Components/Tabletes/Tabletes.js";
import Apple from "./Components/Phones/Apple.js";
import Samsung from "./Components/Phones/Samsung.js";
import Xiaomi from "./Components/Phones/Xiaomi.js";
import ProductDetail from "./Components/ProductDetail/ProductDetail.js";
import Cases from "./Components/Accessories/Cases.js";
import Headphones from "./Components/Accessories/Headphones.js";
import Watches from "./Components/Accessories/Watches.js";
import Login from "./Components/LogInSignUp/Login.js";
import SignUp from "./Components/LogInSignUp/SignUp.js";
import { UserProvider } from "./Context/UserContext.js";
import Checkout from "./Components/Checkout/Checkout.js";
import Chargers from "./Components/Accessories/Chargers.js";
import Thanks from "./Components/Thanks/Thanks.js";
import AdminLayout from "./Components/Admin/AdminLayout.js";
import ProductManagement from './Components/Admin/ProductManagement.js'
import OrderManagement from './Components/Admin/OrderManagement.js'
import UserManagement from './Components/Admin/AdminUsers.js'
import AdminDashboard from './Components/Admin/AdminDashboard.js'

export default function App() {
  return (
    <ProductProvider>
      <UserProvider>
        <CartProvider>
          <Router>
            <header className="App-header bg-white dark:text-white dark:!bg-slate-700">
              <NavBar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Apple" element={<Apple />} />
                <Route path="/Samsung" element={<Samsung />} />
                <Route path="/Xiaomi" element={<Xiaomi />} />
                <Route path="/Tabletes" element={<Tablets />} />
                <Route path="/Cases" element={<Cases />} />
                <Route path="/Headphones" element={<Headphones />} />
                <Route path="/Watches" element={<Watches />} />
                <Route path="/ProductDetail/:productId" element={<ProductDetail />} />
                <Route path="/Cart" element={<Cart />} />
                <Route path="/PersonalArea" element={<PersonalArea />} />
                <Route path="/About" element={<About />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/FAQ" element={<FAQ />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/SignUp" element={<SignUp />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/Chargers" element={<Chargers />} />
                <Route path="/Thanks" element={<Thanks />} />
                <Route path="/admin" element={<AdminLayout />} />
                <Route path="admin/products" element={<ProductManagement />} />
                <Route path="admin/users" element={<UserManagement />} />
                <Route path="admin/products" element={<ProductManagement />} />
                <Route path="admin/orders" element={<OrderManagement />} />
                <Route path="admin/dashboard" element={<AdminDashboard />} />
              </Routes>
            </header>
          </Router>
        </CartProvider>
      </UserProvider>
    </ProductProvider>
  );
}
