import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const OrderManagement = () => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [statusFilter, setStatusFilter] = useState('all');

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        try {
            const response = await axios.get('http://localhost:3000/api/admin/orders');
            console.log("Orders response", response.data)
            const updatedOrders = await Promise.all(response.data.map(async (order) => {
                // שליפת פרטי המוצר לכל פריט בהזמנה
                const productsWithDetails = await Promise.all(order.products.map(async (productItem) => {
                    const productDetails = await fetchProductDetails(productItem._id);
                    console.log("Product details: ", productDetails);
                    return {
                        ...productItem,
                        ...productDetails // מוסיפים את פרטי המוצר לתוך הפריט
                    };
                }));
                return {
                    ...order,
                    products: productsWithDetails
                };
            }));
            setOrders(updatedOrders);
        } catch (error) {
            toast.error('Failed to fetch orders');
        }
    };

    const updateOrderStatus = async (orderId, newStatus) => {
        try {
            await axios.put(`http://localhost:3000/api/admin/orders/${orderId}`, {
                status: newStatus
            });
            toast.success('Order status updated');
            fetchOrders();
        } catch (error) {
            toast.error('Failed to update order status');
        }
    };

    const filteredOrders = statusFilter === 'all' 
        ? orders 
        : orders.filter(order => order.status === statusFilter);

    const fetchProductDetails = async (productId) => {
        try {
            const response = await axios.get(`http://localhost:3000/api/products/${productId}`);
            return response.data; // מחזיר את פרטי המוצר
        } catch (error) {
            console.error('Failed to fetch product details:', error);
            return null;
        }
    };
    
    return (
        <div className="p-6">
            <h2 className="text-2xl font-bold mb-6">Order Management</h2>
            
            {/* Filters */}
            <div className="mb-6">
                <select 
                    value={statusFilter} 
                    onChange={(e) => setStatusFilter(e.target.value)}
                    className="p-2 border rounded"
                    >
                    <option value="all">All Orders</option>
                    <option value="pending">Pending</option>
                    <option value="processing">Processing</option>
                    <option value="shipped">Shipped</option>
                    <option value="delivered">Delivered</option>
                    <option value="cancelled">Cancelled</option>
                </select>
            </div>

            {/* Orders List */}
            <div className="space-y-4">
                {filteredOrders.map(order => (
                    <div key={order._id} className="bg-white p-4 rounded-lg shadow">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="font-bold">Order #{order._id.slice(-6)}</h3>
                            <span className={`px-3 py-1 rounded text-white ${
                                order.status === 'pending' ? 'bg-yellow-500' :
                                order.status === 'processing' ? 'bg-blue-500' :
                                order.status === 'shipped' ? 'bg-purple-500' :
                                order.status === 'delivered' ? 'bg-green-500' :
                                'bg-red-500'
                            }`}>
                                {order.status}
                            </span>
                        </div>
                        
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div>
                                <p className="text-sm text-gray-600">Customer</p>
                                <p>{order.userId?.fullName}</p>
                                <p>{order.userId?.email}</p>
                            </div>
                            <div>
                                <p className="text-sm text-gray-600">Shipping Address</p>
                                <p>{order.addressShipping}</p>
                            </div>
                        </div>

                        {/* Products */}
                        <div className="border-t pt-4">
                            <p className="font-bold mb-2">Products:</p>
                            {order.products.map((productItem) => {
                                console.log("productItem ", productItem);
                                return ( // החזר את ה-JSX כאן
                                    <div key={productItem.productId?._id} className="flex items-center mb-2 space-x-4">
                                        <img 
                                            src={productItem.productId?.image}  
                                            alt={productItem.productId?.name}
                                            className="w-16 h-16 object-cover rounded"
                                        />
                                        <span className="font-semibold">{productItem.productId?.name}</span>
                                        <span>Quantity: {productItem.productId?.quantity}</span>
                                        <span>${productItem.productId?.price}</span>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="border-t pt-4 mt-4">
                            <p className="text-xl font-bold">Total: ${order.totalAmount}</p>
                        </div>

                        {/* Actions */}
                        <div className="mt-4 flex gap-2">
                            <select 
                                value={order.status}
                                onChange={(e) => updateOrderStatus(order._id, e.target.value)}
                                className="p-2 border rounded"
                            >
                                <option value="pending">Pending</option>
                                <option value="processing">Processing</option>
                                <option value="shipped">Shipped</option>
                                <option value="delivered">Delivered</option>
                                <option value="cancelled">Cancelled</option>
                            </select>
                            
                            <button 
                                onClick={() => setSelectedOrder(order)}
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                            >
                                View Details
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {/* Order Details Modal */}
            {selectedOrder && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                        <h3 className="text-xl font-bold mb-4">Order Details</h3>
                        {/* Add detailed order information here */}
                        <button 
                            onClick={() => setSelectedOrder(null)}
                            className="mt-4 bg-gray-500 text-white px-4 py-2 rounded"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderManagement;
