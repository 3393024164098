import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './TopSellingProducts.css';
const TopSellingProducts = () => {
  const navigate = useNavigate();
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('http://localhost:3000/api/admin/top-selling')
      .then(response => {
        const filteredProducts = response.data.filter(product => product.salesCount > 0);
        setTopSellingProducts(filteredProducts);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching top selling products:', error);
        setLoading(false);
      });
  }, []);

  if (loading) return <p>Loading...</p>;

  const viewProductDetails = (productId) => {
    navigate(`/ProductDetail/${productId}`);  };

  return (
    <div className="container mx-auto my-8 p-4">
      <h2 className="text-2xl font-semibold mb-6 text-center tittleProducts">Top Selling Products</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {topSellingProducts.map(product => (
          <div key={product._id} className="border rounded-lg p-4 shadow-lg bg-white">
            <img src={product.image} alt={product.name} className="w-full h-54 object-cover mb-4 rounded-md " />
            <h3 className="text-xl font-bold mb-2">{product.name}</h3>
            <p className="text-gray-500 mb-2">Price: ${product.price}</p>
            <p className="text-gray-700 mb-4">Sales: {product.salesCount}</p>
            <button 
              onClick={() => viewProductDetails(product._id)} 
              className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600"
            >
              View Details
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopSellingProducts;
