import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import deliveryIcon from "../../assents/images/icons/delivery.jpg";
import securityIcon from "../../assents/images/icons/security.jpg";
import returnIcon from "../../assents/images/icons/return.jpg";
import './About.css';

const backgroundImage = require("../../assents/images/AboutUs.jpg");

const cardData = [
  {
    id: 1,
    title: "Fast Shipping",
    text: "Lightning-fast delivery",
    image: securityIcon,
  },
  {
    id: 2,
    title: "Buyer Protection",
    text: "Shop safe, stay secure",
    image: deliveryIcon,
  },
  {
    id: 3,
    title: "Free Returns",
    text: "Easy, no-cost returns",
    image: returnIcon,
  },
];

function Basic() {

  return (
    <Container style={{ marginTop: "90px" }}>
      <div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "40px",
            height: "300px",
            textWrap: "pretty",
          }}
        >
          <h1 className="about_title dark:text-slate-300" style={{marginTop:"30px"}}>About Our Store</h1>
          <p className="p" style={{ marginTop:"30px", fontSize: "23px"}}>
            Welcome to Mobile-Store, your premier destination for cutting-edge
            mobile technology and accessories. We're passionate about bringing
            you the latest and greatest in smartphones, headphones, and
            protective gear, all in one convenient place. What sets us apart is
            our unwavering commitment to quality, innovation, and customer
            satisfaction. We carefully curate our product selection to ensure
            that every item we offer meets our high standards for performance,
            durability, and style.
          </p>
        </div>
      </div>

      <Row className="justify-content-center">
        {cardData.map((card) => {
          return (
            <Col key={card.id} md={4} className="mb-4">
              <Card style={{ width: "18rem", textAlign: "center", marginTop:"50px" }}>
                <Card.Body>
                  <div
                    style={{
                      fontSize: "4px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "150px",
                    }}
                  >
                    <img
                      src={card.image}
                      alt={card.title}
                      style={{ width: "100px", alignItems: "center" }}
                    />
                  </div>
                  <Card.Title style={{ padding: "15px" }} variant="">
                    {card.title}
                  </Card.Title>
                  <Card.Text>{card.text}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default Basic;
