import React, { useEffect, useState } from 'react';
import CardProduct from '../CardProduct/CardProduct';
import axios from 'axios';

const SamsungPhones = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("http://localhost:3000/api/products");
        const SamsungProducts = response.data.filter(product => product.categoryId === "phones" && product.companyId === "samsung");
        setProducts(SamsungProducts);
      } catch (error) {
        console.error("Error fetching Xiaomi products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="products">
      {products.map((product, index) => (
        <CardProduct
          key={index}
          product={product}
        />
      ))}
    </div>
  );
};

export default SamsungPhones;
