import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

export const useProductView = () => {
    const { productId: encodedProductId } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectColor, setSelectColor] = useState("");
    const [selectSize, setSelectSize] = useState("");
    const [selectQuantity, setSelectQuantity] = useState(1);

    // Decode the product ID if it's base64 encoded
    const productId = encodedProductId ? decodeURIComponent(encodedProductId) : null;

    const handleQuantityChange = ({ target: { value } }) => {
        setSelectQuantity(Number(value));
    };

    const getImage = (colour) => {
        if (!product?.attributes?.image?.data) return "";

        const image = product.attributes.image.data.find((img) =>
            img.attributes?.name?.toLowerCase().includes(colour.toLowerCase())
        );
        return image?.attributes?.url || "";
    };

    useEffect(() => {
        if (product?.attributes) {
            const { attributes } = product;
            if (attributes.colours?.length > 0) {
                setSelectColor(attributes.colours[0].name);
            }
            if (attributes.sizes?.length > 0) {
                setSelectSize(attributes.sizes[0].name);
            }
        }
    }, [product]);

    useEffect(() => {
        const fetchProduct = async () => {
            if (!productId) {
                console.error("No product ID provided");
                setError("No product ID provided");
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                setError(null);

                const url = `http://localhost:3000/api/products/${productId}`;

                const response = await axios.get(url, {
                    params: { populated: true },
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

                // console.log("Full API Response:", {
                //     status: response.status,
                //     headers: response.headers,
                //     data: response.data
                // });

                const productData = response.data;

                if (!productData ) {
                    throw new Error("Invalid product data format");
                }

                setProduct(productData);
            } catch (error) {
                console.error("Product fetch error:", {
                    message: error.message,
                    response: error.response,
                    request: error.request
                });

                if (error.response?.status === 404) {
                    setError("Product not found. Please check the product ID.");
                } else if (error.response) {
                    setError(`Server error: ${error.response.status} - ${error.response.statusText}`);
                } else if (error.request) {
                    setError("No response received from server. Please check your connection.");
                } else {
                    setError("Error fetching product data. Please try again.");
                }
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [productId, encodedProductId]);

    return {
        product,
        loading,
        error,
        getImage,
        selectColor,
        selectSize,
        selectQuantity,
        setSelectSize,
        setSelectColor,
        handleQuantityChange,
    };
};
