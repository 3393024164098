// import { FaMobileAlt } from "react-icons/fa";
import "./home.css";
import bg from "../../assents/images/For_Ads/AppleAds/D.jpg";
import bg2 from "../../assents/images/For_Ads/xiaomiAds/xiaomiMain.jpg";
import bg3 from "../../assents/images/For_Ads/AccsAds/acc2.jpg";
import { Carousel } from "react-bootstrap";
// import SearchBar from '../SearchBar/SearchBar.js';
import TopSellingProducts from '../TopSellingProducts/TopSellingProducts.js';
export default function Home() {
  return (
    <div className="home-container dark:bg-slate-800">
      <Carousel>
        <Carousel.Item>
          <a href={"/ProductDetail/673349775c88226590c7d379"} target="_self">
            <img src={bg} className="c-img" alt="slide 1" />
          </a>
        </Carousel.Item>

        <Carousel.Item>
          <a href={"/Xiaomi"} target="_self">
            <img src={bg2} className="c-img" alt="slide 2" />
          </a>
        </Carousel.Item>

        <Carousel.Item>
          <a href={"/Headphones"} target="_self">
            <img src={bg3} className="c-img" alt="slide 3" />
          </a>
        </Carousel.Item>
      </Carousel>
      <div className="icon-whtsapp"></div>
      <TopSellingProducts/>
    </div>
  );
}
