import { useState } from "react"
import { motion, AnimatePresence } from 'framer-motion'
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
export default function FAQ() {
    const [activeQuestion, setActiveQuestion] = useState (null);
    const questions = [
        {
            id: 1,
            question: "How to place an order on the website?",
            answer: "To place an order, select the desired product, add it to the cart, and in the cart click to pay to complete the purchase process. You will be asked to enter shipping and payment information."
        },
        {
            id: 2,
            question: "Is it possible to pay by credit card?",
            answer: "Yes, we accept credit cards, and you can pay using payment services such as PayPal"
        },
        {
            id: 3,
            question: "How long does the delivery take to arrive??",
            answer: "The delivery time varies depending on the customer's address and the selected delivery method. Usually, shipping takes between 3 and 5 business days."
        },
        {
            id: 4,
            question: "Can I return a product if I am not satisfied?",
            answer: "Yes, you can return a product within 7 days of receiving the product, provided it is in its original condition and has not been used. Please contact our customer service to begin the return process."
        },
        {
            id: 5,
            question: "Is there a warranty on the products?",
            answer: "Yes, all products on the site come with a manufacturer's warranty for at least one year. You can contact us in case of a malfunction during the warranty period."
        },
        {
            id: 6,
            question: "How can I track my order?",
            answer: "After placing the order, you will receive a tracking number that will allow you to track the shipment through the shipping company. In addition, you can check the order status through your account on the website."
        },
        {
            id: 7,
            question: "Is it possible to upgrade an old mobile phone?",
            answer: "Yes, we offer trade-in services for old mobile phones. Contact us for a valuation of your old device and for more details on the upgrade process."
        },
        {
            id: 8,
            question: "Do you have a physical store?",
            answer: "Currently we operate mainly online."
        },
        {
            id: 9,
            question: "How can I contact customer service?",
            answer: "You can contact our customer service through a contact form on the website, by email, WhatsApp, or by phone at the number listed on the 'Contact Us' page."
        },
        {
            id: 10,
            question: "Is it possible to purchase accessories for phones?",
            answer: "Yes, we offer a wide range of accessories such as covers, screen protectors, chargers, headphones and more."
        },
        
    ]
    return (
        <div className="w-screen h-screen flex justify-center items-center mt-5">
            <div className="w-[89%] m-auto max-w-[1400px] bg-gray-300 p-8 rounded-lg shadow-md mt-5">
                <h2 className="text-2xl mb-6 font-semibold dark:text-slate-800">Frequently Asked questions</h2>
                {questions.map((q) => {
                    return(
                    <div key={q.id} className="mb-4 last:mb-0 dark:text-slate-800">
                        <button className="w-full text-left text-xl focus:outline-none p-4
                        bg-gray-100 rounded-lg shadow-md justify-between items-center "
                        onClick={() => setActiveQuestion(activeQuestion === q.id ? null
                            : q.id )}>
                        {q.question}
                        {activeQuestion === q.id ?
                        (
                            <FaMinusCircle/>
                        ) : <FaPlusCircle/> }    
                        </button>
                        <AnimatePresence>
                            {activeQuestion === q.id &&
                            (
                                <motion.div
                                initial={{opacity: 0,
                                    height: 0
                                }}
                                animate={{opacity: 1, height: "auto"}}
                                exit={{opacity: 0, height: 0}}
                                className="mt-2 text-gray-600 ml-4"
                                >
                                <p>{q.answer}</p>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    )
                })
                }
            </div>
            {/* <h1 className="dark:text-white">FAQ</h1> */}
        </div>
    )
}