import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    userName: '',
    role: 'user',
    isActive: true,
    addressShipping: '',
    phone: '',
    password: '',
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('http://localhost:3000/api/admin/users');
      setUsers(response.data);
    } catch (error) {
      toast.error('Failed to load users');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      let response;
      if (editUser) {
        response = await axios.put(`http://localhost:3000/api/admin/users/${editUser._id}`, formData, {
          headers: { 'Content-Type': 'application/json' }
        });
      } else {
        response = await axios.post('http://localhost:3000/api/admin/users', formData, {
          headers: { 'Content-Type': 'application/json' }
        });
      }

      toast.success(editUser ? 'The user has been successfully updated' : 'The user has been successfully added');
      setEditUser(null);
      setShowDialog(false);
      setFormData({
        email: '',
        fullName: '',
        userName: '',
        role: 'user',
        isActive: true,
        addressShipping: '',
        phone: '',
        password: ''
      });
      await fetchUsers();
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const response = await axios.delete(`http://localhost:3000/api/admin/users/${userId}`);

        toast.success('המשתמש נמחק בהצלחה');
        await fetchUsers();
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    }
  };

  const openEditDialog = (user) => {
    setEditUser(user);
    setFormData({
      email: user.email || '',
      fullName: user.fullName || '',
      userName: user.userName || '',
      role: user.role || 'user',
      isActive: user.isActive || false,
      addressShipping: user.addressShipping || '',
      phone: user.phone || '',
      password: user.password || ''
    });
    setShowDialog(true);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">User management</h2>
        <button
          onClick={() => {
            setEditUser(null);
            setFormData({
              email: '',
              fullName: '',
              userName: '',
              role: 'user',
              isActive: true,
              addressShipping: '',
              phone: '',
              password: ''
            });
            setShowDialog(true);
          }}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Add a new user
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {users.map(user => (
          <div key={user._id} className="bg-white p-4 shadow-lg rounded-lg">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="font-bold text-lg">{user.fullName}</h3>
                <p className="text-gray-600">{user.email}</p>
              </div>
              <span className={`px-2 py-1 rounded text-sm ${user.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                {user.isActive ? 'active' : 'inactive'}
              </span>
            </div>
            <div className="mb-4">
              <p className="text-xl text-gray-600">role: {user.role}</p>
              {user.phone && <p className="text-xl text-gray-600">Phone: {user.phone}</p>}
              {user.addressShipping && <p className="text-xl text-gray-600">Address Shipping: {user.addressShipping}</p>}
              {user.userName && <p className="text-xl text-gray-600">User Name: {user.userName}</p>}
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => openEditDialog(user)}
                className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600"
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(user._id)}
                className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {showDialog && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-96">
            <h3 className="text-xl font-bold">{editUser ? 'Edit user' : 'Add new user'}</h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className="w-full p-2 border rounded"
                required
              />
              <input
                type="text"
                placeholder="Full name"
                value={formData.fullName}
                onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                className="w-full p-2 border rounded"
                required
              />
              <input
                type="text"
                placeholder="User Name"
                value={formData.userName}
                onChange={(e) => setFormData({ ...formData, userName: e.target.value })}
                className="w-full p-2 border rounded"
                required
              />
              <input
                type="tel"
                placeholder="Phone"
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                className="w-full p-2 border rounded"
              />
              <input
                type="text"
                placeholder="Shipping Address"
                value={formData.addressShipping}
                onChange={(e) => setFormData({ ...formData, addressShipping: e.target.value })}
                className="w-full p-2 border rounded"
              />
              <input
                type="password"
                placeholder="Password"
                value={formData.password}
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                className="w-full p-2 border rounded"
              />
              <select
                value={formData.role}
                onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                className="w-full p-2 border rounded"
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={formData.isActive}
                  onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
                  className="rounded"
                />
                <label>Active user</label>
              </div>
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => setShowDialog(false)}
                  className="px-4 py-2 border rounded hover:bg-gray-100"
                >
                  Cancellation
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  {editUser ? 'Update' : 'Add'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagement;