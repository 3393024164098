
import React, { useState, useEffect, useContext } from "react";
import { useCart } from "../../Context/CartContext";
import UserContext from "../../Context/UserContext.js";
import { useNavigate } from "react-router-dom";
import "./Checkout.css";
import { toast } from "react-toastify";
import axios from "axios";



export default function Checkout() {

    const { userDetails, setUserDetails } = useContext(UserContext);
    const { cart, clearCart } = useCart();
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        ShippingAddress : "",
        cardNumber: "",
        expirationDate: "",
        cvv: "",
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (cart.length === 0) {
            navigate("/");
        }
    }, [cart, navigate]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    }; 

    const totalPrice = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);
    
    const submitOrder = async () => {
        try {
            const orderData = {
                userId: userDetails.id,
                products: cart.map(item => {
                    console.log(item)
                    return {
                    
                    productId: item._id,
                    quantity: item.quantity,
                }}),
                totalAmount: totalPrice,
            };
            console.log("userDetails: ", userDetails);            
            console.log("Order data: ", orderData);            
            const response = await axios.post("http://localhost:3000/api/orders", orderData);
            console.log("Response from server:", response.data);   

            const updatedUserDetails = {
                ...userDetails,
                orders: Array.isArray(userDetails.orders) ? [...userDetails.orders, response.data] : [response.data]
            };
            console.log("Updated user details:", updatedUserDetails);


            setUserDetails(updatedUserDetails);
            return response.data;
        } catch (error) {
            console.error("Error submitting order:", error);
            throw new Error("There was a problem sending the order");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.cardNumber.length !== 16) {
            toast.error("Card number should be 16 digits long only!");
            return;
        }

        if (formData.expirationDate.length !== 4) {
            toast.error("Expiry date should be only 4 digits long!");
            return;
        }

        if (formData.cvv.length !== 3) {
            toast.error("CVV should be only 3 digits long!");
            return;
        }


        try {
            await submitOrder();  
            toast("The order was successfully placed!", { duration: 6000 });
            clearCart();
            localStorage.removeItem("cart");

            setFormData({
                firstName: "",
                lastName: "",
                ShippingAddress: "",
                cardNumber: "",
                expirationDate: "",
                cvv: "",
            });
        } catch (error) {
            toast.error("An error occurred while placing the order");
        }
    };

    return (
        <div className="checkout-container">
            <h1 className="title">Payment 💲</h1>

            <div className="cart-items">
                <h2 className="Details">Order Details:</h2>
                {cart.map((item) => (
                    <div key={item.id} className="cart-item dark:text-slate-800">
                        <img src={item.image} alt={item.name} className="item-image" />
                        <div className="item-details">
                            <h4>{item.name}</h4>
                            <p className="amount">Quantity: {item.quantity}</p>
                            <p className="price">Price: ₪{item.price}</p>
                        </div>
                    </div>
                ))}

                <h3 className="total  dark:text-slate-800">Total ₪{totalPrice}</h3>
            </div>

            <form className="checkout-form  dark:text-slate-800" onSubmit={handleSubmit}>
                <h3 className="Details">User Details:</h3>
                <div className="form-group ">
                    <label className="subTitle">First name:</label>
                    <input
                    placeholder="avi"
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label className="subTitle">Last name:</label>
                    <input
                    placeholder="cohen"
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label className="subTitle">Shipping Address:</label>
                    <input
                    placeholder="Jabotinsky 10 ashdod"
                        type="text"
                        name="addressShipping"
                        value={formData.addressShipping}
                        onChange={handleChange}
                        required
                    />
                </div>

                <h3 className="Details">Payment Details:</h3>
                <div className="form-group">
                    <label className="subTitle">Card Number:</label>
                    <input
                    placeholder="1234 5678 9101 1121"
                        type="text"
                        name="cardNumber"
                        value={formData.cardNumber}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label className="subTitle">Expiration Date:</label>
                    <input
                        type="text"
                        name="expirationDate"
                        value={formData.expirationDate}
                        onChange={handleChange}
                        required
                        placeholder="MM/YY"
                    />
                </div>
                <div className="form-group">
                    <label className="subTitle">CVV:</label>
                    <input
                    placeholder="123"
                        type="text"
                        name="cvv"
                        value={formData.cvv}
                        onChange={handleChange}
                        required
                    />
                </div>

                <button type="submit" className="checkout-btn">
                    Pay Now
                </button>
            </form>
        </div>
    );
}