// components/admin/AdminLayout.jsx
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
    Package, Users, ShoppingCart, 
    BarChart2, LogOut, Menu 
} from 'lucide-react';

export default function AdminLayout({ children }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);

    const menuItems = [
        { path: '/admin/dashboard', icon: BarChart2, label: 'Dashboard' },
        { path: '/admin/products', icon: Package, label: 'Products' },
        { path: '/admin/users', icon: Users, label: 'Users' },
        { path: '/admin/orders', icon: ShoppingCart, label: 'Orders' }
    ];

    const handleLogout = () => {
        localStorage.removeItem('adminToken');
        navigate('/admin/login');
    };

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Sidebar */}
            <aside className={`${isSidebarOpen ? 'w-64' : 'w-20'} 
                             bg-slate-800 text-white transition-all duration-300 mt-28`}>
                <div className="p-4 ">
                    <div className="flex items-center justify-between">
                        {isSidebarOpen && <h2 className="text-xl font-bold ">Admin Panel</h2>}
                        <button onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                                className="p-2 rounded hover:bg-slate-700">
                            <Menu size={20} />
                        </button>
                    </div>
                </div>

                <nav className="mt-4">
                    {menuItems.map((item) => (
                        <Link
                            key={item.path}
                            to={item.path}
                            className={`flex items-center p-4 hover:bg-slate-700
                                      ${location.pathname === item.path ? 'bg-slate-700' : ''}`}
                        >
                            <item.icon size={20} />
                            {isSidebarOpen && <span className="ml-4">{item.label}</span>}
                        </Link>
                    ))}
                    <button
                        onClick={handleLogout}
                        className="flex items-center w-full p-4 hover:bg-slate-700"
                    >
                        <LogOut size={20} />
                        {isSidebarOpen && <span className="ml-4">Logout</span>}
                    </button>
                </nav>
            </aside>

            {/* Main Content */}
            <main className="flex-1 overflow-y-auto">
                <div className="p-6">{children}</div>
            </main>
        </div>
    );
}