import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import './Contact.css';
import Conatct from '../../assents/images/Contact.jpg'
import { FaPhoneAlt } from 'react-icons/fa';
import { ToastContainer, toast } from "react-toastify";
import WhatsAppIcon from '../WhtsappIcon/WhtsappIcon.js';


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const userName = form.current.user_name;
    const email = form.current.email;
    const phone = form.current.phone;
    const message = form.current.message;
    if(!userName || !email || !phone || !message)
    {
      toast.error("Please fill in all fields")
      return; 
    }
    emailjs
      .sendForm(
        "service_8j72psh",
        "template_df5osaz",
        form.current,
        "Kku2A7n8j2XlnQaRf"
      )
    toast("Your message was sent");
    form.current.reset();
  };

  return (
    <>
      <img src={Conatct} alt="Contact" className="contactImg" />
      <div className="Contact_Us">
        <h3>Talk to us</h3>
        <a href="tel:+972537470893" className="flex items-center space-x-2 ">
          <FaPhoneAlt size={24} />
          <span>Call Us</span>
        </a>

      </div>
      <div className="contact-form">
        <form ref={form} onSubmit={sendEmail} >

          <label >Enter Your Name:</label>
          <input type="text" name="user_name" className="dark:text-slate-800" />
          <label>Enter Your Email</label>
          <input type="email" name="user_email" className="dark:text-slate-800" />
          <label>Enter Your Phone</label>
          <input type="text" name="user_phone" className="dark:text-slate-800" />
          <label>Enter Your Message</label>
          <textarea name="message" className="dark:text-slate-800" />
          <input type="submit" value="Send" />
        </form>
      </div>
      <WhatsAppIcon />

    </>
  );
};

export default Contact;