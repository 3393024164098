import React, { useContext, useState, useEffect } from 'react';
import UserContext from "../../Context/UserContext.js";
import './PersonalArea.css';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import iconPersonalArea from '../../assents/images/icons/personal-area-icon.png';
import { toast } from 'react-toastify';

export default function PersonalArea() {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const { fullName, email, addressShipping, userName } = userDetails || {};
  const [orders, setOrders] = useState([]);
  const [productsDetails, setProductsDetails] = useState([]);  
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState(userDetails || {});
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (storedUserDetails) {
      setUserDetails(storedUserDetails);
    }
  }, []);

  useEffect(() => {
    if (userDetails?.userName) {
      navigate("/PersonalArea");
    }
  }, [userDetails, navigate]);

useEffect(() => {
  console.log("effect: ",orders)
},[orders])
  useEffect(() => {
    if (userDetails?.id) {
      const fetchOrders = async () => {
        try {
          const response = await axios.get(`http://localhost:3000/api/orders/user/${userDetails.id}`);
          setOrders(response.data);
           for (const order of orders) {
            for (const product of order.products) {
              const url = `http://localhost:3000/api/products/${product._id}`;
              const res = await axios.get(url) 
              console.log("id", product._id)
              console.log("res", res)
              order = {...order, ...res}
            }
             }
         } catch (error) {
          console.error("Error fetching orders:", error.message);
        }
      };
      fetchOrders();
    }
  }, [userDetails]);
  

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const updateUserProfile = async () => {
    try {
      const response = await axios.put(`http://localhost:3000/api/users/update`, { userId: userDetails.id, updatedData: formData });
      setUserDetails(response.data);
      setEditMode(false);
    } catch (error) {
      console.error("Error updating profile:", error.message);
    }
  };

  const handleLogout = () => {
    toast("You have successfully logged out")
    setUserDetails(null);
    localStorage.removeItem('userDetails');
    navigate('/');
  };

  if (!userDetails) {
    return (
      <div className='card' style={{ boxShadow: "-moz-initial", background: "#091057" }}>
        <h1 style={{ fontSize: "40px", color: "#F3C623" }}>
          Sorry, no user found. Please login to enter personal area!
        </h1>
      </div>
    );
  }

  return (
    <div className="personal-area-container dark:to-slate-800">
      <h1 className="title text-3xl font-bold mb-6">My Profile</h1>
      <img src={iconPersonalArea} alt='img' className='view-icon-pers-area mb-6' />

      <div className="user-details bg-white dark:bg-slate-800 p-6 rounded-lg shadow-lg mb-4">
        <p><strong>Full Name:</strong> {fullName}</p>
        <p><strong>Email:</strong> {email}</p>
        <p><strong>Shipping address:</strong> {addressShipping}</p>
        <p><strong>User Name:</strong> {userName}</p>

        {!editMode && (
          <button onClick={() => setEditMode(true)} className="edit-btn">Edit details</button>
        )}
        <button onClick={handleLogout} className="logout-btn">Logout</button>

        {editMode && (
          <div className="edit-form dark:text-slate-800 mt-6">
            <div className="form-group mb-4">
              <label>Full Name:</label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="input-field"
              />
            </div>
            <div className="form-group mb-4">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="input-field"
              />
            </div>
            <div className="form-group mb-4">
              <label>Address:</label>
              <input
                type="text"
                name="addressShipping"
                value={formData.addressShipping}
                onChange={handleChange}
                className="input-field"
              />
            </div>
            <div className="form-group mb-4">
              <label>UserName:</label>
              <input
                type="text"
                name="userName"
                value={formData.userName}
                onChange={handleChange}
                className="input-field"
              />
            </div>
            <button onClick={updateUserProfile} className="save-btn">Save</button>
          </div>
        )}
      </div>

      <div className="orders-container">
        <h2 className="text-4xl font-semibold mb-4 text-center">My Orders</h2>
        {orders.length > 0 ? (
          orders.map((order) => (
            <div key={order._id} className="bg-white dark:bg-slate-800 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 mb-4 pb-2  ">
              <p className="text-xl font-semibold mb-2"><strong>Order Number:</strong> {order._id.slice(-6)}</p>
              <p className="text-sm text-gray-500 mb-2"><strong>Date:</strong> {new Date(order.date).toLocaleDateString()}</p>
              <p className="text-lg font-medium mb-4"><strong>Total Amount:</strong> {order.totalAmount} $</p>
              <div className="order-products">
                {order.products.map((product) => (
                  <div key={product._id} className="product-item flex items-center justify-between border-b border-gray-300 pb-2">
                    <img src={product.productId.image} alt={product.productId.name} className="w-16 h-16 object-cover rounded" />
                    <p className="product-name flex-1 ml-4 break-words">{product.productId.name } &nbsp; &nbsp;  </p>
                    <p className="product-quantity text-base text-gray-600"><b>Quantity:</b> <b>{product.quantity}</b> </p>
                    <p className="product-price text-base text-gray-600"><b> &nbsp; Price: {product.productId.price} ₪</b></p>

                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p>No orders found.</p>
        )}
      </div>
    </div>
  );
}