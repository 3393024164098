import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const ProductManagement = () => {
    const [products, setProducts] = useState([]);
    const [editProduct, setEditProduct] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        price: '',
        description: '',
        image: '',
        categoryId: '',  // משתנה לקטגוריה
        companyId: '',   // משתנה לחברה
        stock: '',
        sales: '',
    });
    const [sortOption, setSortOption] = useState('');

    const categories = ['phones', 'tabletes', 'cases', 'charger', 'watches', 'Headphones'];
    const companies = ['apple', 'samsung', 'xiaomi'];

    useEffect(() => {
        fetchProducts();
    }, [sortOption]);

    const fetchProducts = async () => {
        try {
            const response = await axios.get('http://localhost:3000/api/admin/products');
            let sortedProducts = [...response.data];

            if (sortOption === 'name') {
                sortedProducts.sort((a, b) => a.name.localeCompare(b.name));
            } else if (sortOption === 'price') {
                sortedProducts.sort((a, b) => a.price - b.price);
            } else if (sortOption === 'company') {
                sortedProducts.sort((a, b) => a.companyId.localeCompare(b.companyId));
            } else if (sortOption === 'category') {
                sortedProducts.sort((a, b) => a.categoryId.localeCompare(b.categoryId));
            } else if (sortOption === 'sales') {
                sortedProducts.sort((a, b) => b.salesCount - a.salesCount);
            }

            setProducts(sortedProducts);
        } catch (error) {
            toast.error('Failed to fetch products');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const productData = {
                ...formData,
                salesCount: parseInt(formData.sales) || 0
            };

            if (editProduct) {
                await axios.put(`http://localhost:3000/api/admin/products/${editProduct._id}`, productData);
                toast.success('Product updated successfully');
            } else {
                await axios.post('http://localhost:3000/api/admin/products', productData);
                toast.success('Product added successfully');
            }

            setFormData({
                name: '',
                price: '',
                description: '',
                image: '',
                categoryId: '',
                companyId: '',
                stock: '',
                sales: ''
            });
            setEditProduct(null);
            fetchProducts();
        } catch (error) {
            console.error(error);
            toast.error(error.response?.data?.message || 'Operation failed');
        }
    };

    return (
        <div className="p-6 m-48 ">
            <h2 className="text-2xl font-bold mb-6 ">Product Management</h2>

            {/* Sort by */}
            <div className="mb-4">
                <select
                    value={sortOption}
                    onChange={(e) => setSortOption(e.target.value)}
                    className="p-2 border rounded"
                >
                    <option value="">Sort by</option>
                    <option value="name">Name</option>
                    <option value="price">Price</option>
                    <option value="company">Company</option>
                    <option value="category">Category</option>
                    <option value="sales">Sales Count</option>
                </select>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md mb-6 dark:text-slate-900">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
                    <input
                        type="text"
                        placeholder="Product Name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        className="p-2 border rounded"
                        required
                    />
                    <input
                        type="number"
                        placeholder="Price"
                        value={formData.price}
                        onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                        className="p-2 border rounded"
                        required
                    />
                    <input
                        type="text"
                        placeholder="Image URL"
                        value={formData.image}
                        onChange={(e) => setFormData({ ...formData, image: e.target.value })}
                        className="p-2 border rounded"
                        required
                    />
                    {/* Category Selection */}
                    <select
                        value={formData.categoryId}
                        onChange={(e) => setFormData({ ...formData, categoryId: e.target.value })}
                        className="p-2 border rounded"
                        required
                    >
                        <option value="">Select Category</option>
                        {categories.map(category => (
                            <option key={category} value={category}>{category}</option>
                        ))}
                    </select>
                    {/* Company Selection */}
                    <select
                        value={formData.companyId}
                        onChange={(e) => setFormData({ ...formData, companyId: e.target.value })}
                        className="p-2 border rounded"
                        required
                    >
                        <option value="">Select Company</option>
                        {companies.map(company => (
                            <option key={company} value={company}>{company}</option>
                        ))}
                    </select>
                    <input
                        type="number"
                        placeholder="Stock"
                        value={formData.stock}
                        onChange={(e) => setFormData({ ...formData, stock: e.target.value })}
                        className="p-2 border rounded"
                        required
                    />
                    <textarea
                        placeholder="Description"
                        value={formData.description}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        className="p-2 border rounded"
                        required
                    />
                    <input
                        type="number"
                        placeholder="Sales"
                        value={formData.sales}
                        onChange={(e) => setFormData({ ...formData, sales: e.target.value })}
                        className="p-2 border rounded"
                    />
                </div>
                <button type="submit" className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                    {editProduct ? 'Update Product' : 'Add Product'}
                </button>
            </form>

            {/* Display products */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 dark:text-slate-900">
                {products.map(product => (
                    <div key={product._id} className="bg-white p-4 rounded-lg shadow">
                        <img src={product.image} alt={product.name} className="w-full h-54 object-cover rounded mb-4" />
                        <h3 className="font-bold text-lg">{product.name}</h3>
                        <p className="text-gray-600">{product.description}</p>
                        <p className="text-xl font-bold mt-2">${product.price}</p>
                        <p className="text-sm">Stock: {product.stock}</p>
                        <p className="text-sm mb-4">Category: {product.categoryId}</p>
                        <p className="text-sm mb-4">Company: {product.companyId}</p>
                        <p className="text-sm mb-4">Sales Count: {product.salesCount}</p>
                        <div className="flex gap-2">
                            <button
                                onClick={() => {
                                    setEditProduct(product);
                                    setFormData(product);
                                    window.scrollTo({ top: 0, behavior: 'smooth' })
                                }}
                                className="bg-yellow-500 text-white px-3 py-1 rounded"
                            >
                                Edit
                            </button>
                            <button
                                onClick={async () => {
                                    if (window.confirm('Are you sure?')) {
                                        try {
                                            await axios.delete(`http://localhost:3000/api/admin/products/${product._id}`);
                                            toast.success('Product deleted');
                                            fetchProducts();
                                        } catch (error) {
                                            toast.error('Failed to delete product');
                                        }
                                    }
                                }}
                                className="bg-red-500 text-white px-3 py-1 rounded"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductManagement;
