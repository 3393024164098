import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState, useContext } from "react";
import UserContext from "../../Context/UserContext.js";
import { CButton, CFormInput, CFormLabel } from "@coreui/react";
import axios from "axios";

export default function SignUp() {
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    addressShipping: "",
    userName: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("Field changed:", name, "New value:", value);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.fullName ||
      !formData.userName ||
      !formData.email ||
      !formData.password ||
      !formData.addressShipping
    ) {
      console.error("All fields are required");
      return;
    }
    try {
      const response = await axios.post(
        "http://localhost:3000/api/users/register",
        formData
      );
      toast.success("You have successfully registered");
      setError("");
      navigate("/");
    } catch (err) {
      setError(err.response?.data?.error || "An error occurred");
      setSuccess("");
    }
  };

  if (userDetails) {
    return (
      <div
        className="card"
        style={{ boxShadow: "-moz-initial", background: "#091057" }}
      >
        <h1 style={{ fontSize: "40px", color: "#F3C623" }}>
          Sorry, you are already logged in...
        </h1>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center py-12 h-full">
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      <div className="mx-auto grid w-[350px] gap-6">
        <div className="grid gap-2 mb-10 text-start">
          <h1 className="text-3xl font-bold">Sign Up</h1>
          <p className="text-balance text-muted-foreground">
            Create an account to get started
          </p>
        </div>

        <form onSubmit={handleSubmit} className="grid gap-4">
          <div className="grid gap-2">
            <CFormLabel htmlFor="name">
              Full Name
              <span className="text-xs font-light ml-2">(optional)</span>
            </CFormLabel>
            <CFormInput
              value={formData.fullName}
              onChange={handleChange}
              name="fullName"
              type="text"
              placeholder="John Doe"
            />
          </div>

          <div className="grid gap-2">
            <CFormLabel htmlFor="addressShipping">Shipping Address</CFormLabel>
            <CFormInput
              name="addressShipping"
              type="text"
              placeholder=""
              value={formData.addressShipping}
              onChange={handleChange}
            />
          </div>

          <div className="grid gap-2">
            <CFormLabel htmlFor="userName">User Name</CFormLabel>
            <CFormInput
              name="userName"
              type="text"
              placeholder=""
              value={formData.userName}
              onChange={handleChange}
            />{" "}
          </div>

          <div className="grid gap-2">
            <CFormLabel htmlFor="email">Email</CFormLabel>
            <CFormInput
              name="email"
              type="email"
              placeholder="m@example.com"
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          <div className="grid gap-2">
            <CFormLabel htmlFor="orders">Password</CFormLabel>
            <CFormInput
              name="password"
              type="password"
              required
              value={formData.password}
              onChange={handleChange}
            />
          </div>

          <CButton type="submit" className="w-full">
            Sign Up
          </CButton>
        </form>

        <div className="mt-4 text-center text-sm">
          Already have an account?{" "}
          <Link to="/Login" className="underline">
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );
}
