import React from "react";
import { useCart } from "../../Context/CartContext";
import "./Cart.css";
import { FaShoppingCart, FaPlus, FaMinus } from "react-icons/fa";
import imgClearAll from "../../assents/images/icons/Clear-Cart-Img.png";
import payIcon from '../../assents/images/icons/pay-icon.png';
import { useNavigate } from "react-router-dom";

export default function Cart() {
  const { cart, removeFromCart, updateQuantity, clearCart } = useCart();
  const navigate = useNavigate();

  const handleCheckout = () => {
    navigate("/checkout"); 
  };

  if (cart.length === 0)
  {
    localStorage.removeItem("cart");
    return (
      <h2
      className="dark dark:text-white text-blue-700"
      style={{ fontSize: "40px", marginLeft: "70px" }}
      >
        Your cart is empty 🙁
      </h2>
    );
  }else {
    localStorage.setItem("cart", JSON.stringify(cart));
  }

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const formatPrice = (price) => {
    if (price >= 100) {
      return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    return price.toString();
  };

  const totalPrice = calculateTotal();

  return (
    <div className="cart-container dark:text-slate-900">
      <img
        src={imgClearAll}
        onClick={clearCart}
        style={{ cursor: "pointer", width: "50px", height: "50px", marginLeft: "70px" }}
        alt="Clear Cart"
      />
      <h4>Click To Clear Cart </h4>

      <ol className="cart-list">
        {cart.map((item) => (
          <li key={item.id} className="cart-item">
            <div className="item-info">
              <img src={item.image} className="item-img" alt={item.name} />

              <span className="item-name">{item.name}</span>
              <div className="item-actions">
                <button
                  className="item-button"
                  onClick={() => updateQuantity(item.id, item.quantity - 1)}
                  disabled={item.quantity <= 1}
                >
                  <FaMinus />
                </button>
                <span className="item-quantity-display">{item.quantity}</span>
                <button
                  className="item-button"
                  onClick={() => updateQuantity(item.id, item.quantity + 1)}
                >
                  <FaPlus />
                </button>
                <button
                  className="item-remove"
                  onClick={() => removeFromCart(item._id)}
                >
                  Delete
                </button>
              </div>
              <span className="item-price">
                {
                Number.isFinite(item.price)?
                 `₪${formatPrice(item.price * item.quantity)}`
                  : "Price not available"
                  }
              </span>
            </div>
          </li>
        ))}
      </ol>
      <div className="cart-total">
        <h2>Total:  
          {Number.isFinite(totalPrice)
            ? `₪${formatPrice(totalPrice)}`
            : "Price not available"}
        </h2>
        <img
          src={payIcon}
          onClick={handleCheckout}
          style={{ cursor: "pointer", width: "50px", height: "50px", marginLeft: "90px" }}
          alt="Proceed to Checkout"
        />
        <h4 className="txt_button-pay-page">Click For Payment</h4>
      </div>
    </div>
  );
}