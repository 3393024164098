import React, { useState, useEffect } from "react";
import { useProductView } from "./useProductView";
import { useCart } from "../../Context/CartContext.js";
import {
    Button,
    Card,
    CardBody,
    CardImg,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Row,
    Form
} from "react-bootstrap";

export default function ProductDetail() {
    const { addToCart } = useCart();
    const {
        product,
        loading,
        error,
        getImage,
        selectColor,
        selectSize,
        selectQuantity,
        setSelectSize,
        setSelectColor,
        handleQuantityChange,
    } = useProductView();

    const [totalPrice, setTotalPrice] = useState(Number(product?.price) || 0);

    useEffect(() => {
        // עדכון המחיר הכולל בכל פעם שהכמות או המחיר משתנים
        setTotalPrice(Number(product?.price) * selectQuantity);
    }, [selectQuantity, product]);

    if (loading) return (
        <div className="d-flex justify-content-center p-5">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );

    if (error) return (
        <div className="alert alert-danger m-3" role="alert">
            {error}
        </div>
    );

    if (!product) return null;

    const quantityOptions = Array.from({ length: 5 }, (_, i) => i + 1);

    const imageUrl = product.image.startsWith('http')
        ? product.image
        : `http://localhost:3000${product.image}`;
    
    const handleClickAdd = () => {
        addToCart(product, selectQuantity);
    };

    return (
        <Card className="product-details m-3">
            <Row className="g-0">
                <Col sm="12" md="4">
                    {imageUrl ? (
                        <CardImg className="img-fluid" src={imageUrl} alt={product.name} />
                    ) : (
                        <div className="text-center p-4">No image available</div>
                    )}
                </Col>
                <Col sm="12" md="8">
                    <CardBody>
                        <CardTitle tag="h4" className="mb-3">{product.name}</CardTitle>
                        <CardText className="mb-3">{product.description}</CardText>
                        <CardSubtitle tag="h5" className="mb-3">
                            Price: ${totalPrice.toFixed(2)} {/* מציג את המחיר הכולל */}
                        </CardSubtitle>
                        <CardText className="text-muted mb-4">
                             {product.quantity > 0 ? `${product.quantity} items left` : 'Out of stock'} 
                        </CardText>

                         {product.sizes?.length > 0 && (
                            <div className="mb-4">
                                <CardSubtitle tag="h6" className="mb-2">Sizes:</CardSubtitle>
                                <div className="d-flex gap-2">
                                    {product.sizes.map((size) => (
                                        <Button
                                            key={size.name}
                                            variant={selectSize === size.name ? "primary" : "outline-primary"}
                                            onClick={() => setSelectSize(size.name)}
                                        >
                                            {size.name}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        )}

                        {product.colours?.length > 0 && (
                            <div className="mb-4">
                                <CardSubtitle tag="h6" className="mb-2">Colors:</CardSubtitle>
                                <div className="d-flex gap-2">
                                    {product.colours.map((colour) => (
                                        <Button
                                            key={colour.name}
                                            variant={selectColor === colour.name ? "primary" : "outline-primary"}
                                            onClick={() => setSelectColor(colour.name)}
                                        >
                                            {colour.name}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        )}

                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="quantity">Quantity</Form.Label>
                            <Form.Select
                                value={selectQuantity}
                                onChange={handleQuantityChange}
                                id="quantity"
                                style={{ maxWidth: '100px' }}
                            >
                                {quantityOptions.map((number) => (
                                    <option key={number} value={number}>{number}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Button
                            variant="primary"
                            size="lg"
                            onClick={handleClickAdd}
                        >
                            Add to cart
                        </Button>
                    </CardBody>
                </Col>
            </Row>
        </Card>
    );
}
