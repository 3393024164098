import React, { useEffect, useState } from 'react';
import CardProduct from '../CardProduct/CardProduct';
import axios from 'axios';

const Chargers = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log("Fetching products...");
        const response = await axios.get("http://localhost:3000/api/products");
        const ChargersData = response.data.filter(product => product.categoryId === "charger");
        setProducts(ChargersData);
      } catch (error) {
        console.error("Error fetching Chargers products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="products">
    {products.map((product, index) => (
      <CardProduct
        key={index}
        product={product}
      />
    ))}
  </div>
  );
};

export default Chargers;
