import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { NavLink, Link, useNavigate } from "react-router-dom";
import DarkMode from "../../Components/DarkMode/DarkMode.js";
import { useCart } from "../../Context/CartContext.js";
import { PiShoppingCartBold } from "react-icons/pi";
import { LuUserCircle2 } from "react-icons/lu";
import imgHome from "../../assents/images/logo-w-untitled-min.png";
import SearchBar from "../../Components/SearchBar/SearchBar.js";
import "./navBar.css";

const CustomDropdown = ({ title, items, onLinkClick }) => {
  const navigate = useNavigate();
  return (
    <div className="nav-item dropdown">
      <span className="nav-link dropdown-toggle">{title}</span>
      <div className="dropdown-menu">
        {items.map((item, index) => (
          <NavLink
            key={index}
            to={item.to}
            className="dropdown-item"
            onClick={onLinkClick}
          >
            {item.label}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default function NavBar() {
  const { cart } = useCart();
  const cartItemCount = cart.reduce((total, item) => total + item.quantity, 0);
  const [expanded, setExpanded] = useState(false);
  const closeNavBar = () => setExpanded(false);

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      className="navBarAll"
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand href="/" className="nav-link-home">
          <img src={imgHome} alt="Logo" className="logo" />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <CustomDropdown
              title="Phones"
              items={[
                { to: "/Apple", label: "Apple" },
                { to: "/Samsung", label: "Samsung" },
                { to: "/Xiaomi", label: "Xiaomi" },
              ]}
              onLinkClick={closeNavBar}
            />
            <Link to="/Tabletes" className="nav-link" onClick={closeNavBar}>
              Tablets
            </Link>
            <CustomDropdown
              title="Accessories"
              items={[
                { to: "/Cases", label: "Cases" },
                { to: "/Headphones", label: "Headphones" },
                { to: "/Watches", label: "Watches" },
                { to: "/Chargers", label: "Chargers" },
              ]}
              onLinkClick={closeNavBar}
            />

            {/* <Link to="/PersonalArea" className="nav-link" onClick={closeNavBar}>
              Personal Area
            </Link> */}

            <CustomDropdown
              title="More"
              items={[
                { to: "/about", label: "About Us" },
                { to: "/contact", label: "Contact" },
                { to: "/faq", label: "FAQ" },
                { to: "/PersonalArea", label: "Personal Area" },
              ]}
              onLinkClick={closeNavBar}
            />
          </Nav>

          <SearchBar />

          <Nav>
            <Link to="/Cart" className="nav-link" onClick={closeNavBar}>
              <div className="cart-icon-container">
                <PiShoppingCartBold color="white" className="cart-icon" />
                {cartItemCount > 0 && (
                  <span className="cart-item-count">{cartItemCount}</span>
                )}
              </div>
            </Link>

            <Link to="/Login" className="nav-link" onClick={closeNavBar}>
              <LuUserCircle2 className="Log" />
            </Link>
            <div className="darkM">
              <DarkMode />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
